/* ensure 100% height on body & root div */

:root {
  --zidx-zero: 0;
  --zidx-base: 1;
  --zidx-main-content: 10;
  --zidx-profile-menu: 20;
  --zidx-header: 30;
  --zidx-modal-overlay: 200;
  --zidx-modal: 201;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  --reach-menu-button: 1;
}

.default {
  cursor: default;
}

[data-reach-menu-button] {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

[data-reach-menu-list] {
  display: block;
  white-space: nowrap;
  border: 0;
  background: #032a3c;
  text-align: center;
  box-shadow: -6px 5px 15px -3px rgba(0, 0, 0, 0.25);
  outline: none;
  padding: 1rem 0;
}

[data-reach-menu-item] {
  cursor: pointer;
  display: block;
  color: #4cb5f5;
  text-decoration: initial;
  padding: 0.5rem 2rem;
  letter-spacing: 1px;
}

[data-reach-menu-item]:focus,
[data-reach-menu-item]:hover {
  color: #ffffff;
  background: transparent;
}

[data-reach-menu-item][data-selected] {
  outline: none;
}

reach-portal div[data-reach-menu-popover] {
  min-width: 2.25rem;
}

[data-reach-dialog-overlay='true'] {
  z-index: var(--zidx-modal-overlay);
}

.customToolTipTheme {
  opacity: 1 !important;
  background-color: #fff !important;
  padding: 22px 28px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}

.paypal_button {
  display: inline;
  width: 100%;
}

.modalBody > div {
  padding: 0;
}

.payment-input::placeholder {
  color: black;
  opacity: 1;
}

/* for block elements */
.layered {
  display: grid;
}

.layered > label,
.layered > span {
  grid-column-start: 1;
  grid-row-start: 1;
}

/* or for inline elements */
.inline-layered {
  display: inline-grid;
}

.inline-layered > * {
  grid-column-start: 1;
  grid-row-start: 1;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}