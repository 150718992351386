body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

.container {
  margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
.container::before {
  content: " ";
	display: table;
}
.container::after {
  clear: both;
}

@media(min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media(min-width: 992px) {
  .container {
    width: 970px;
  }
}


@media(min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media(min-width: 1400px) {
  .container {
    width: 1375px;
  }
}